import type { FC } from "react";

import { Col, Form, Input, Select } from "antd";
import { useFormikContext } from "formik";

import type { CreateProductBody } from "@omi-lab/cresus-typescript";

export const Summary: FC = () => {
  const { values } = useFormikContext<CreateProductBody>();

  return (
    <Col
      style={{
        backgroundColor: "#fafafa",
        border: "1px dashed #d9d9d9",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
        <Form.Item label="Name" name="name">
          <Input defaultValue={values.name} disabled />
        </Form.Item>
        <Form.Item label="Slug" name="slug">
          <Input defaultValue={values.slug} disabled />
        </Form.Item>
        <Form.Item label="Type" name="type">
          <Input defaultValue={values.type} disabled />
        </Form.Item>
        <Form.Item label="Visibility" name="visibility">
          <Input defaultValue={values.visibility} disabled />
        </Form.Item>
        <Form.Item label="Billing interval" name="billingInterval">
          <Input
            defaultValue={`Billed every ${values.billingInterval} ${values.billingIntervalUnit}`}
            disabled
          />
        </Form.Item>
        <Form.Item label="Commitment interval" name="commitmentInterval">
          <Input
            defaultValue={`Billed every ${values.commitmentInterval} ${values.commitmentIntervalUnit}`}
            disabled
          />
        </Form.Item>
        <Form.Item label="Features" name="features">
          <Select
            disabled
            mode="tags"
            defaultValue={values.features || []}
          ></Select>
        </Form.Item>
      </Form>
    </Col>
  );
};
