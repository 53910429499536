import type { FC } from "react";
import { useEffect, useState } from "react";

import {
  Breadcrumb,
  Col,
  Divider,
  Input,
  notification,
  Pagination,
  Row,
  Select,
  Steps,
  Table,
} from "antd";
import Text from "antd/lib/typography/Text";

import type { Product, UpdateProductBody } from "@omi-lab/cresus-typescript";
import {
  PlanFeatureName,
  PlanVisibility,
  ProductVisibility,
} from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";
import { useListProducts } from "../hooks";

import { Addons } from "./Addons";

export const Products: FC = () => {
  const [nameIncludes, setNameIncludes] = useState<string>();
  const [visibility, setVisibility] = useState<ProductVisibility[]>([
    ProductVisibility.Public,
    ProductVisibility.Hidden,
  ]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const client = useClientsStore((store) => store.productsClient);

  useEffect(() => {
    setPage(1);
  }, [visibility]);

  const { products, setProducts, pageCount, isLoading } = useListProducts({
    nameIncludes,
    visibility,
    page,
    pageSize,
  });

  const updateProduct = async (id: string, body: UpdateProductBody) => {
    try {
      setIsUpdating(true);
      await client
        .updateProduct({
          productId: id,
          body,
          returnRelatedAddons: true,
          returnRelatedFeatures: true,
          returnRelatedTiers: true,
        })
        .then(({ data }) =>
          setProducts(
            products
              .map((product) => (product.id === id ? data : product))
              .filter(
                (product) =>
                  !visibility || visibility.includes(product.visibility),
              ),
          ),
        );
      notification.success({
        message: "The product was successfully updated.",
        duration: 1,
      });
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <Row gutter={16} justify="start" style={{ marginBottom: "10px" }}>
        <Col span={3}>
          <Input
            placeholder="Name"
            value={nameIncludes}
            onChange={(e) => setNameIncludes(e.target.value)}
          />
        </Col>
        <Col span={3}>
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            placeholder="Visibility"
            mode="multiple"
            filterOption={false}
            value={visibility}
            onSelect={(v) => setVisibility([...visibility, v])}
            onDeselect={(v) => setVisibility(visibility.filter((i) => i !== v))}
            onClear={() => setVisibility([])}
          >
            {Object.values(ProductVisibility).map((v) => (
              <Select.Option value={v} key={v}>
                {v}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Table
        dataSource={products.map((product) => ({
          ...product,
          key: product.id,
        }))}
        pagination={false}
        loading={isLoading}
        columns={[
          {
            title: "",
            key: "index",
            dataIndex: "index",
            width: "5%",
            align: "center",
            render: (_, __, index) => <p>{index + (page - 1) * pageSize}</p>,
          },
          {
            title: "ID",
            key: "id",
            dataIndex: "id",
            width: "10%",
            align: "center",
          },
          {
            title: "Name",
            key: "name",
            dataIndex: "name",
            width: "10%",
            align: "center",
          },
          {
            title: "Slug",
            key: "slug",
            dataIndex: "slug",
            width: "10%",
            align: "center",
          },
          {
            title: "Type",
            key: "type",
            dataIndex: "type",
            width: "10%",
            align: "center",
          },
          {
            title: "Billing",
            key: "billing",
            width: "10%",
            align: "center",
            render: (_, record: Product) => (
              <p>
                Every {record.billingInterval} {record.billingIntervalUnit}
              </p>
            ),
          },
          {
            title: "Commitment",
            key: "commitment",
            width: "10%",
            align: "center",
            render: (_, record: Product) => (
              <p>
                Every {record.commitmentInterval}{" "}
                {record.commitmentIntervalUnit}
              </p>
            ),
          },
          {
            title: "Visibility",
            key: "visibility",
            width: "5%",
            align: "center",
            render: (_, record: Product) => (
              <Select
                value={record.visibility}
                onChange={(v) =>
                  updateProduct(record.id, {
                    visibility: v,
                  })
                }
              >
                {Object.values(PlanVisibility).map((visibility) => (
                  <Select.Option key={visibility} value={visibility}>
                    {visibility}
                  </Select.Option>
                ))}
              </Select>
            ),
          },
          {
            title: "Features",
            key: "features",
            width: "15%",
            align: "center",
            render: (_, record: Product) => (
              <Select
                style={{ minWidth: "200px" }}
                disabled={isUpdating}
                mode="multiple"
                value={(record.features || []).map(({ name }) => name)}
                maxTagCount={3}
                onSelect={(name) =>
                  updateProduct(record.id, {
                    features: (record.features || [])
                      .map((feature) => feature.name)
                      .concat(name),
                  })
                }
                onDeselect={(name) =>
                  updateProduct(record.id, {
                    features: (record.features || [])
                      .filter((feature) => feature.name !== name)
                      .map((feature) => feature.name),
                  })
                }
              >
                {Object.values(PlanFeatureName).map((name) => (
                  <Select.Option value={name} key={name}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            ),
          },
          {
            title: "Price",
            key: "tiers",
            width: "15%",
            align: "center",
            render: (_, record: Product) => {
              if (!record.tiers?.at(0) || !record?.tiers?.at(-1)) {
                return null;
              }
              return (
                <p>
                  From {record.tiers?.at(0)?.unitAmount ?? 1 / 100} to{" "}
                  {record?.tiers?.at(-1)?.unitAmount ?? 1 / 100} per SKU
                </p>
              );
            },
          },
        ]}
        expandedRowRender={(record) => (
          <>
            <Divider />
            <Breadcrumb style={{ marginBottom: "20px" }}>
              <Breadcrumb.Item>Details</Breadcrumb.Item>
              <Breadcrumb.Item>Tiers</Breadcrumb.Item>
            </Breadcrumb>
            <Steps progressDot current={record.tiers!.length - 1} size="small">
              {record.tiers!.map((tier) => (
                <Steps.Step
                  key={tier.productId}
                  title={`${tier.upTo || "+Inf"}`}
                  description={<Text>{tier.unitAmount / 100} per SKU</Text>}
                />
              ))}
            </Steps>
            <Divider />
            <Breadcrumb>
              <Breadcrumb.Item>Details</Breadcrumb.Item>
              <Breadcrumb.Item>Addons</Breadcrumb.Item>
            </Breadcrumb>
            <Addons product={record} />
          </>
        )}
      />
      <Pagination
        style={{ marginTop: 20 }}
        current={page}
        total={pageCount * pageSize}
        onChange={setPage}
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        showSizeChanger
      />
    </Col>
  );
};
