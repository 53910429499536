import type { FC } from "react";
import { useState } from "react";

import { Button, notification, Row, Space } from "antd";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

interface Props {
  quoteId: string;
}

export const GetLink: FC<Props> = (props) => {
  const [isGeneratingToken, setIsGeneratingToken] = useState(false);

  const [quotesClient] = useClientsStore((store) => [store.quotesClient]);

  const generateLink = async () => {
    try {
      setIsGeneratingToken(true);

      const token = await quotesClient
        .getQuoteToken({
          quoteId: props.quoteId,
        })
        .then(
          (response) =>
            `${process.env.REACT_APP_MANAGE_URL}/organization-settings?quoteId=${props.quoteId}&token=${response.data}&modal=QUOTES_MODAL`,
        );

      navigator.clipboard.writeText(token);

      notification.success({
        message: "Successfully copied the token.",
      });
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsGeneratingToken(false);
    }
  };

  return (
    <Row>
      <Space direction="horizontal" style={{ width: "100%" }}>
        <Button
          type="primary"
          loading={isGeneratingToken}
          disabled={isGeneratingToken}
          onClick={() => generateLink()}
        >
          Copy link
        </Button>
      </Space>
    </Row>
  );
};
