import type { FC } from "react";
import { useState } from "react";

import { useHistory } from "react-router-dom";

import { Button, Modal, Space, Steps } from "antd";
import { FormikProvider, useFormik } from "formik";

import type { CreateProductBody } from "@omi-lab/cresus-typescript";
import {
  IntervalUnit,
  ProductSlug,
  ProductType,
  ProductVisibility,
} from "@omi-lab/cresus-typescript";

import { extractErrorMessageFromError } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";
import { STEPS, steps } from "../utils/steps";

import { Addons } from "./Addons";
import { Details } from "./Details";
import { ValidationScheme } from "./Form.schema";
import { Summary } from "./Summary";

export const Form: FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const client = useClientsStore((store) => store.productsClient);

  const formik = useFormik({
    initialValues: {
      name: "",
      slug: ProductSlug.PerSku,
      visibility: ProductVisibility.Public,
      type: ProductType.Sku,
      billingIntervalUnit: IntervalUnit.Month,
      billingInterval: 1,
      commitmentIntervalUnit: IntervalUnit.Year,
      commitmentInterval: 1,
      features: [],
      tiers: [],
      addons: [],
      defaultCouponId: undefined,
    } as CreateProductBody,
    onSubmit: (values) => console.log(values),
    validateOnMount: false,
    validationSchema: ValidationScheme,
  });

  const createPlan = async () => {
    try {
      setIsLoading(true);

      await client.createProduct({
        body: {
          ...formik.values,
          addons: [
            ...(formik.values.addons || []).map((addon) => ({
              ...addon,
              type:
                addon.tiers && addon.tiers.length > 0
                  ? ProductType.SkuAddonTiered
                  : addon.type,
              tiers:
                addon.tiers && addon.tiers.length > 0 ? addon.tiers : undefined,
              unitAmount:
                !addon.tiers || addon.tiers.length === 0
                  ? addon.unitAmount
                  : undefined,
            })),
          ],
        },
      });

      Modal.success({
        content: "Successfully created the product",
        closable: true,
        okText: "Go to products",
        onOk: () => history.push("/billing/products"),
      });
    } catch (error: unknown) {
      Modal.error({
        content: extractErrorMessageFromError(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderForm = (step: number) => {
    switch (step) {
      case STEPS.FILL_DETAILS:
        return <Details />;
      case STEPS.CREATE_ADDONS:
        return <Addons />;
      case STEPS.SUMMARY:
        return <Summary />;
    }
  };

  const getNextButton = (step: number) => {
    switch (step) {
      case STEPS.SUMMARY:
        return (
          <Button
            type="primary"
            onClick={() => createPlan()}
            disabled={
              !Object.keys(formik.touched).length ||
              !!Object.keys(formik.errors).length ||
              isLoading
            }
            loading={isLoading}
          >
            Create
          </Button>
        );
      default:
        return (
          <Button
            type="primary"
            onClick={() => setCurrentStep(currentStep + 1)}
            disabled={
              !Object.keys(formik.touched).length ||
              !!Object.keys(formik.errors).length
            }
          >
            Next
          </Button>
        );
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Steps current={currentStep}>
        {steps.map((step) => (
          <Steps.Step key={step.key} title={step.title} />
        ))}
      </Steps>
      <div className="steps-action">
        {currentStep > 0 && (
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Previous
          </Button>
        )}
        {getNextButton(currentStep)}
      </div>
      <div className="steps-content">
        <FormikProvider value={formik}>
          {renderForm(currentStep)}
        </FormikProvider>
      </div>
    </Space>
  );
};
